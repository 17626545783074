import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useAuth } from '../../Auth/Provider'

type Params = {
  id: string
}

type ProjectRow = {
  id: string
  name: string
}

export default function Project(): React.JSX.Element {
  const { isAuthenticated, getToken, login } = useAuth()
  const params = useParams<Params>()

  const [project, setProject] = useState<ProjectRow | null>(null)

  useEffect(() => {
    getToken().then((token) => {
      fetch('/api/v1/projects/' + params.id, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then((response) => response.json())
        .then((data) => setProject(data))
    })
  }, [getToken])

  if (!isAuthenticated) {
    login()
    return <></>
  }

  return (
    <section className="content">
      <div className="container">
        <ul className="breadcrumbs">
          <li>
            <Link to={'/'}>Проекты</Link>
          </li>
          <li>{project ? project.name : '...'}</li>
        </ul>

        {project !== null ? (
          <div data-testid="project">
            <h1>{project.name}</h1>
          </div>
        ) : null}
      </div>
    </section>
  )
}
