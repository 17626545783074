import React from 'react'
import { Outlet } from 'react-router-dom'
import { useAuth } from '../Auth/Provider'
import styles from './Layout.module.scss'
import Header from './Header'
import Footer from './Footer'

export default function Layout() {
  const { isAuthenticated, login } = useAuth()

  if (!isAuthenticated) {
    login()
    return <></>
  }

  return (
    <div className={styles.app}>
      <Header />
      <main data-testid="dashboard">
        <Outlet />
      </main>
      <Footer />
    </div>
  )
}
