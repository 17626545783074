import React from 'react'
import NotFound from './NotFound'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthProvider } from './Auth/Provider'
import Auth from './Auth'
import Layout from './Dashboard/Layout'
import Dashboard from './Dashboard/Dashboard'
import Project from './Dashboard/Project/Project'

export default function App() {
  return (
    <AuthProvider
      authorizeUrl={process.env.REACT_APP_AUTH_URL + '/auth'}
      tokenUrl={process.env.REACT_APP_AUTH_URL + '/token'}
      clientId="frontend"
      scope="common"
      redirectPath="/auth"
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/projects/:id" element={<Project />} />
          </Route>
          <Route path="/auth" element={<Auth />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  )
}
