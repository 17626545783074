import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../Auth/Provider'
import styles from './Header.module.scss'

export default function Header() {
  const { logout } = useAuth()

  return (
    <header className={styles.header}>
      <div className="container">
        <div className={styles.content}>
          <div className={styles.logo}>
            <Link to="/">udeo.ru</Link>
          </div>
          <nav>
            <a
              data-testid="logout-link"
              href="#"
              onClick={(e) => {
                e.preventDefault()
                logout()
              }}
            >
              Выход
            </a>
          </nav>
        </div>
      </div>
    </header>
  )
}
