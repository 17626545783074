import React from 'react'
import styles from './Footer.module.scss'

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className={styles.content}>
          <div>&copy; udeo.ru</div>
        </div>
      </div>
    </footer>
  )
}
